import {
  IReceiptInvoiceDetails,
  IVatReceipt,
  PaymentStatus,
} from "../../models/charging";
import StandardResponse from "../../models/standard-response";
import BaseService from "../../services/base-service";

export default class PaymentService {
  getChargingAuthorisationStatus = async (
    data
  ): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `web-pay/charge-sessions/get-session-status`,
        method: "POST",
        data: { ...data, hideSpinner: true },
      });

      return new StandardResponse<any>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
  stopCharge = async (data): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `web-pay/charge-sessions/stop-session`,
        method: "POST",
        data,
      });

      return new StandardResponse<any>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
  getDownloadReceiptData = async (
    data: any
  ): Promise<StandardResponse<IReceiptInvoiceDetails>> => {
    try {
      const res = await BaseService({
        url: `web-pay/charge-sessions/charge-payment-history/export`,
        method: "POST",
        data,
      });

      return new StandardResponse<IReceiptInvoiceDetails>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getPaymentDetails = async (
    data
  ): Promise<StandardResponse<PaymentStatus[]>> => {
    try {
      const res = await BaseService({
        url: `web-pay/payment/status`,
        method: "POST",
        data,
      });

      return new StandardResponse<PaymentStatus[]>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (err) {
      return Promise.reject(err);
    }
  };

  createSettlementPaymentInvoice = async (
    data
  ): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: "web-pay/payment/create/settlement/invoice",
        method: "POST",
        data,
      });
      return new StandardResponse<any>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };

  tryAgainPayment = async (data): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: "web-pay/payment/perform/settlement",
        method: "POST",
        data,
      });
      return new StandardResponse<any>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
}
