import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { IChargingProgressProps } from "../../types/propTypes";
import { useParams } from "react-router-dom";
import ChargingGraph from "./ChargingGraph";
import {
  ChargeSession,
  IReceiptInvoiceDetails,
  PaymentStatus,
} from "../../models/charging";
import moment from "moment";
import ChargingService from "../../services/charging/charging-service";
import StandardResponse from "../../models/standard-response";
import { toast } from "react-toastify";
import { IPaymentInvoice } from "../../models/payment";
import {
  getDNAPaymentScript,
  getPublicConfigurationValue,
} from "../../utils/payment";
import { useNavigate } from "react-router-dom";
import { Common } from "../../utils/constants";

const chargingService = new ChargingService();
const getChargeDurationFromDate = (lastUpdateDateTime, startDateTime) => {
  lastUpdateDateTime = moment(lastUpdateDateTime);
  startDateTime = moment(startDateTime);
  return lastUpdateDateTime.diff(startDateTime, "seconds");
};

const ChargingProgress: FC<IChargingProgressProps> = (props) => {
  const { t, chargeSession, isPaymentRetry, isSessionCompleted } = props;

  const { code, connector_id, invoice_id } = useParams();

  const [sessionDetails, setSessionDetails] = useState<ChargeSession | null>(
    null
  );
  const [graphData, setGraphData] = useState<number[][]>([[0, 0]]);
  const [showCompletedMessage, setShowCompletedMessage] =
    useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showWaitingMessage, setShowWaitingMessage] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState<PaymentStatus | null>(
    null
  );
  const [paymentFailMessage, setpaymentFailMessage] = useState<string>("");
  const [amountToPay, setAmountToPay] = useState<number | null>(null);
  const [charityAmount, setCharityAmount] = useState<number | null>(null);
  const [thankYouMessage, setThankYouMessage] = useState<string>("");
  const [showTryAgain, setShowTryAgain] = useState<boolean>(false);
  const [showPayWithDifferentCard, setShowPayWithDifferentCard] =
    useState<boolean>(true);
  const [settlementAmount, setSettlementAmount] = useState<number>(0);
  const [payWithDifferentCardCount, setPayWithDifferentCardCount] =
    useState<number>(0);
  const [tryAgainCount, setTryAgainCount] = useState<number>(0);
  const [allPaymentsFailed, setAllPaymentsFailed] = useState<boolean>(false);
  const [allPaymentsFailedMessage, setAllPaymentsFailedMessage] =
    useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("success") === "true") {
      localStorage.removeItem("tryAgainCount");
      localStorage.removeItem("payWithDifferentCardCount");
    }

    const storedTryAgainCount = localStorage.getItem("tryAgainCount");
    const storedPayWithDifferentCardCount = localStorage.getItem(
      "payWithDifferentCardCount"
    );

    if (storedPayWithDifferentCardCount) {
      setPayWithDifferentCardCount(
        parseInt(storedPayWithDifferentCardCount, 10)
      );
      if (parseInt(storedPayWithDifferentCardCount, 10) >= 2) {
        setShowPayWithDifferentCard(false);
      }
    } else {
      localStorage.setItem("payWithDifferentCardCount", "0");
    }

    if (storedTryAgainCount) {
      setTryAgainCount(parseInt(storedTryAgainCount, 10));
      if (parseInt(storedTryAgainCount, 10) >= 1) {
        setShowTryAgain(false);
      }
    } else {
      localStorage.setItem("tryAgainCount", "0");
    }

    if (storedTryAgainCount && storedPayWithDifferentCardCount) {
      if (
        parseInt(storedTryAgainCount, 10) >= 1 &&
        parseInt(storedPayWithDifferentCardCount, 10) >= 2
      ) {
        setAllPaymentsFailed(true);
        setAllPaymentsFailedMessage(
          t<string>("chargingProgress.label.allPaymentsFailedMessage")
        );
      }
    }

    if (isSessionCompleted) {
      if (
        chargeSession &&
        chargeSession.charge_percentage === 0 &&
        Number(chargeSession.cost) === 0
      ) {
        toast.error(t<string>("authoriseSession.unableToConnect"));
        navigate("/");
      }
      setShowCompletedMessage(true);
    }
    if (isPaymentRetry || isSessionCompleted) {
      setShowWaitingMessage(true);
      (document as any)
        .getElementById("loading")
        .classList.remove("spinner-disable");
      setTimeout(async () => {
        (document as any)
          .getElementById("loading")
          .classList.add("spinner-disable");
        setShowWaitingMessage(false);
        await getPaymentDetails();
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (chargeSession && !isPaymentRetry && !isSessionCompleted) {
      const chargeDurationMinutes = getChargeDurationFromDate(
        chargeSession.last_update_time,
        chargeSession.start_time
      );

      const graphUpdateArray = graphData;
      const graphUpdate = [
        chargeDurationMinutes,
        chargeSession.total_power_consumed,
      ];

      graphUpdateArray.push(graphUpdate);

      setSessionDetails(chargeSession);
      setGraphData(graphUpdateArray);

      if (chargeSession.charge_status === "COMPLETED") {
        setShowCompletedMessage(true);
        setShowWaitingMessage(true);
        setTimeout(async () => {
          await getPaymentDetails();
        }, 5000);
      } else if (chargeSession.charge_status === "INVALID") {
        setShowErrorMessage(true);
      }
    }
  }, [chargeSession]);

  const handleStopCharge = async () => {
    const token = localStorage.getItem(`SESSION_TOKEN_${code}`);
    const session_id = sessionDetails?.session_id;
    const inputData = {
      invoice_id,
      token,
      session_id,
    };

    await chargingService
      .stopCharge(inputData)
      .then((res: StandardResponse<any>) => {
        if (res.Data && res.Data.result === "ACCEPTED") {
          toast.success(t<string>("chargingProgress.label.stopSession"));
          setShowCompletedMessage(true);
          setShowWaitingMessage(true);
          setTimeout(async () => {
            await getPaymentDetails();
          }, 5000);
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
  };

  const handleDownloadInvoice = async () => {
    const token = localStorage.getItem(`SESSION_TOKEN_${code}`);
    const inputData = {
      token,
      invoice_id,
      is_download: true,
    };
    await chargingService
      .getDownloadReceiptData(inputData)
      .then(async (res: StandardResponse<IReceiptInvoiceDetails>) => {
        const pdfDownloadLink = res.Data.invoice_url;
        if (pdfDownloadLink !== "") {
          window.open(pdfDownloadLink, "_blank");
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
  };

  const getPaymentDetails = async () => {
    const token = localStorage.getItem(`SESSION_TOKEN_${code}`);

    const inputData = {
      invoice_id,
      token,
    };

    await chargingService
      .getPaymentDetails(inputData)
      .then(async (res: StandardResponse<PaymentStatus[]>) => {
        const paymentData: any = res.Data;
        if (paymentData) {
          const settledStatus = paymentData.find(
            (status) =>
              status.pre_auth_payment_status === "SETTLED" &&
              status.settlement_payment_status === "SETTLED"
          );
          let amountToPay = 0;
          let thankYouMessage = t("chargingProgress.label.thankYouForCharging");
          let actualAmount = 0;
          if (settledStatus) {
            if (Number(settledStatus.settlement_amount) > 0) {
              amountToPay = Number(
                (
                  Number(settledStatus.settlement_amount) +
                  Number(settledStatus.pre_auth_amount)
                ).toFixed(2)
              );
            } else {
              amountToPay = Number(
                Number(settledStatus.pre_auth_amount).toFixed(2)
              );
            }
            actualAmount = settledStatus.actual_amount
              ? Number(settledStatus.actual_amount)
              : 0;
            if (
              settledStatus.charity_amount &&
              Number(settledStatus.charity_amount) > 0
            ) {
              thankYouMessage = t("chargingProgress.label.thankYouWithCharity");
              let charityAmount = Number(settledStatus.charity_amount);
              thankYouMessage = thankYouMessage.replace(
                "##CHARGE_AMOUNT##",
                actualAmount.toFixed(2)
              );
              thankYouMessage = thankYouMessage.replace(
                "##CHARITY_AMOUNT##",
                charityAmount.toFixed(2)
              );
              setCharityAmount(charityAmount);
            } else {
              thankYouMessage = t(
                "chargingProgress.label.thankYouWithoutCharity"
              );
              thankYouMessage = thankYouMessage.replace(
                "##CHARGE_AMOUNT##",
                actualAmount.toFixed(2)
              );
            }
            setThankYouMessage(thankYouMessage);
            setAmountToPay(amountToPay);
            setPaymentDetails(settledStatus);
          } else {
            const preAuthFailedStatus = paymentData.find(
              (status) =>
                status.pre_auth_payment_status === "FAILED" &&
                (status.settlement_payment_status === "FAILED" ||
                  status.settlement_payment_status === "SETTLEMENT_FAILED")
            );
            if (preAuthFailedStatus) {
              if (
                Number(preAuthFailedStatus.settlement_amount) !== 0 &&
                Number(preAuthFailedStatus.pre_auth_amount) !== 0
              ) {
                let paymentFailedMessage = t(
                  "chargingProgress.label.paymentFailed"
                );

                amountToPay = Number(
                  (
                    Number(preAuthFailedStatus.settlement_amount) +
                    Number(preAuthFailedStatus.pre_auth_amount)
                  ).toFixed(2)
                );

                paymentFailedMessage = paymentFailedMessage.replace(
                  "##AMOUNT##",
                  amountToPay.toString()
                );

                if (
                  preAuthFailedStatus.charity_amount &&
                  Number(preAuthFailedStatus.charity_amount) > 0
                ) {
                  setCharityAmount(Number(preAuthFailedStatus.charity_amount));
                }
                const settelment_amount = Number(
                  Number(preAuthFailedStatus.settlement_amount).toFixed(2)
                );
                setSettlementAmount(settelment_amount);
                if (settelment_amount < 0) {
                  amountToPay = Number(
                    Number(preAuthFailedStatus.actual_amount).toFixed(2)
                  );
                  paymentFailedMessage = t(
                    "chargingProgress.label.settlementFailedWithNegativeValue"
                  );
                  if (
                    parseInt(localStorage.getItem("tryAgainCount"), 10) >= 1
                  ) {
                    setShowTryAgain(false);
                    paymentFailedMessage = t(
                      "chargingProgress.label.tryAgainFailed"
                    );
                  } else {
                    setShowTryAgain(true);
                  }
                }
                setpaymentFailMessage(paymentFailedMessage);
                setAmountToPay(amountToPay);
                setPaymentDetails(preAuthFailedStatus);
              } else if (
                Number(preAuthFailedStatus.settlement_amount) === 0 &&
                Number(preAuthFailedStatus.pre_auth_amount) === 0
              ) {
                toast.info(t<string>("authoriseSession.unableToConnect"));
                navigate("/");
              }
            } else {
              const settlementFailedStatus = paymentData.find(
                (status) =>
                  status.pre_auth_payment_status === "SETTLED" &&
                  (status.settlement_payment_status === "FAILED" ||
                    status.settlement_payment_status === "SETTLEMENT_FAILED")
              );

              if (settlementFailedStatus) {
                const settelment_amount = Number(
                  Number(settlementFailedStatus.settlement_amount).toFixed(2)
                );
                setSettlementAmount(settelment_amount);

                let paymentFailedMessage;

                if (settelment_amount > 0) {
                  amountToPay = Number(
                    Number(settlementFailedStatus.settlement_amount).toFixed(2)
                  );
                  paymentFailedMessage = t(
                    "chargingProgress.label.settlementFailed"
                  );
                  paymentFailedMessage = paymentFailedMessage
                    .replace(
                      "##PRE_AUTH_AMOUNT##",
                      settlementFailedStatus.pre_auth_amount
                    )
                    .replace(
                      "##SETTLEMENT_AMOUNT##",
                      settlementFailedStatus.settlement_amount
                    );
                } else if (settelment_amount < 0) {
                  amountToPay = Number(
                    Number(settlementFailedStatus.actual_amount).toFixed(2)
                  );
                  paymentFailedMessage = t(
                    "chargingProgress.label.settlementFailedWithNegativeValue"
                  );
                  if (
                    parseInt(localStorage.getItem("tryAgainCount"), 10) >= 1
                  ) {
                    setShowTryAgain(false);
                    paymentFailedMessage = t(
                      "chargingProgress.label.tryAgainFailed"
                    );
                  } else {
                    setShowTryAgain(true);
                  }
                }

                if (
                  settlementFailedStatus.charity_amount &&
                  Number(settlementFailedStatus.charity_amount) > 0
                ) {
                  setCharityAmount(
                    Number(settlementFailedStatus.charity_amount)
                  );
                }

                setpaymentFailMessage(paymentFailedMessage);
                setAmountToPay(amountToPay);
                setPaymentDetails(settlementFailedStatus);
              }
            }
          }
        }

        setShowWaitingMessage(false);
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
  };

  const handlePayWithDifferentCard = async () => {
    const enable_google_apple_pay = await getPublicConfigurationValue(
      Common.ConfigurationKey.ENABLE_GOOGLE_PAY_APPLE_PAY_DNA
    );
    const newPayWithDifferentCardCount = payWithDifferentCardCount + 1;
    localStorage.setItem(
      "payWithDifferentCardCount",
      newPayWithDifferentCardCount.toString()
    );
    const paymentInvoice = await createPaymentInvoice();
    if (paymentInvoice) {
      let amount = amountToPay || 0;
      let isEnableDonation = false;
      let donationAmount = 0;
      (document as any)
        .getElementById("loading")
        .classList.remove("spinner-disable");
      const isTestMode =
        paymentInvoice.isTestMode === undefined ||
        paymentInvoice.isTestMode === null ||
        paymentInvoice.isTestMode === true
          ? true
          : false;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = getDNAPaymentScript(isTestMode);
      script.nonce = "ils+vsV+OAN1z2QUGBzoyQ==";
      script.async = true;
      script.onload = () => {
        (window as any).DNAPayments.configure({
          autoRedirectDelayInMs: 3000,
          scopes: {
            allowHosted: true,
          },
          paymentMethods: [
            {
              name: (window as any).DNAPayments.paymentMethods.BankCard,
            },
            ...(enable_google_apple_pay === "true"
              ? [
                  {
                    name: (window as any).DNAPayments.paymentMethods.GooglePay,
                  },
                  {
                    name: (window as any).DNAPayments.paymentMethods.ApplePay,
                  },
                ]
              : []),
          ],
          isTestMode: isTestMode,
          paymentTimeoutInSeconds: 900,
          isEnableDonation,
        });

        const invoiceId = paymentInvoice.invoice_id;

        const paymentData = {
          paymentSettings: {
            terminalId: paymentInvoice.terminal,
            returnUrl: `${window.location.origin}/charge-station/${code}/${connector_id}/${invoice_id}/charge?paymentRetry=true&&success=true`,
            failureReturnUrl: `${window.location.origin}/charge-station/${code}/${connector_id}/${invoice_id}/charge?paymentRetry=true&&success=false`,
            callbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/web-pay/payment/dna-webhook/${invoiceId}`,
            failureCallbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/web-pay/payment/dna-webhook/${invoiceId}`,
            cscMatrix: 22,
            paMatrix: 3,
          },
          customerDetails: {
            accountDetails: {
              accountId: invoice_id,
            },
          },
          periodic: {
            periodicType: "ucof",
          },
          invoiceId: invoiceId,
          amount: +amount,
          auth: { access_token: paymentInvoice.access_token },
          language: "en",
          description: "Payment to InstaVolt",
          transactionType: paymentInvoice.transaction_type || "SALE",
          currency: paymentInvoice.currency,
          donationAmount,
        };

        (window as any).DNAPayments.openPaymentPage(paymentData);
      };
      script.onerror = () => {
        (document as any)
          .getElementById("loading")
          .classList.add("spinner-disable");
        toast.error(t<string>("paymentFail.common.error"));
      };
      document.body.appendChild(script);
    }
  };

  const createPaymentInvoice = async () => {
    const token = localStorage.getItem(`SESSION_TOKEN_${code}`);
    let paymentInvoice: any = "";
    const inputData = {
      parent_invoice_id: invoice_id,
      token,
    };
    await chargingService
      .createSettlementPaymentInvoice(inputData)
      .then(async (res: StandardResponse<IPaymentInvoice>) => {
        if (res.Data) {
          paymentInvoice = res.Data;
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
    return paymentInvoice;
  };

  const handleTryAgain = async () => {
    const newTryAgainCount = tryAgainCount + 1;
    localStorage.setItem("tryAgainCount", newTryAgainCount.toString());
    setShowTryAgain(false);

    const token = localStorage.getItem(`SESSION_TOKEN_${code}`);

    const inputData = {
      invoice_id,
      token,
    };

    await chargingService
      .tryAgainPayment(inputData)
      .then((res: StandardResponse<any>) => {
        if (res.Data.settlement_payment_status === "SETTLED") {
          setShowCompletedMessage(true);
          setShowWaitingMessage(true);
          setTimeout(async () => {
            await getPaymentDetails();
          }, 5000);
          localStorage.removeItem("tryAgainCount");
          localStorage.removeItem("payWithDifferentCardCount");
        } else {
          setpaymentFailMessage(
            t<string>("chargingProgress.label.tryAgainFailed")
          );
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
  };

  const backbtnclick = () => {
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("chargingProgress.title")}</title>
      </Helmet>

      <div className="charging-progress">
        {!isPaymentRetry && !isSessionCompleted && (
          <>
            <div className="station-title-div">
              <div className="marker-img-div">
                <img
                  src={require("../../assets/images/ic_marker_big_icon.png")}
                  className="marker-img"
                  alt="marker"
                />
              </div>
              <div className="station-name">
                <h6 className="station-title">
                  {sessionDetails?.station_name
                    ? `${sessionDetails?.station_name} (${code})`
                    : `${code}`}
                </h6>
              </div>
            </div>
            {graphData.length <= 1 ? (
              <div className="info-text">
                <span>{t<string>("chargingProgress.label.info")}</span>
              </div>
            ) : (
              <div className="graph-div">
                <ChargingGraph graphData={graphData} />
              </div>
            )}
            <div className="row no-gutters justify-content-between charge-details-wrapper">
              <div className="col-12 charge-details-card">
                <div className="detail-box col-6 box-border-right box-border-bottom">
                  <div>
                    <span className="value-span">
                      {sessionDetails?.charge_duration || 0}
                    </span>
                  </div>
                  <div>
                    <label className="info-label">
                      {t<string>("chargingProgress.label.chargeDuration")}
                    </label>
                  </div>
                </div>
                <div className="detail-box col-6 box-border-bottom">
                  <div>
                    <span className="value-span">
                      {sessionDetails?.total_power_consumed
                        ? sessionDetails.total_power_consumed.toFixed(2)
                        : "0.00"}
                    </span>
                    <span className="info-span">
                      {t<string>("chargingProgress.label.kwh")}
                    </span>
                  </div>
                  <div>
                    <label className="info-label">
                      {t<string>("chargingProgress.label.energyConsumed")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 charge-details-card">
                <div className="detail-box col-6 box-border-right">
                  <div>
                    <span className="value-span">
                      {sessionDetails?.charge_percentage || 0}
                    </span>
                    <span className="info-span">
                      {t<string>("chargingProgress.label.percentage")}
                    </span>
                  </div>
                  <div>
                    <label className="info-label">
                      {t<string>("chargingProgress.label.chargeLevel")}
                    </label>
                  </div>
                </div>
                <div className="detail-box col-6">
                  <div>
                    <span className="value-span">
                      £{sessionDetails?.cost || 0}
                    </span>
                  </div>
                  <div>
                    <label className="info-label">
                      {t<string>("chargingProgress.label.cost")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {!showCompletedMessage &&
              !showErrorMessage &&
              sessionDetails !== null && (
                <div className="clearfix mt-2 stop-charge-div">
                  <button
                    type="button"
                    className="btn btn-primary mr-2 stop-charge-btn"
                    onClick={() => handleStopCharge()}
                  >
                    <span className="stop-charge-span">
                      {t<string>("chargingProgress.label.stopCharge")}
                    </span>
                    <img
                      src={require("../../assets/images/ic_charge_med_icon.png")}
                      className="stop-charge-img"
                      alt="marker"
                    />
                  </button>
                </div>
              )}
          </>
        )}

        {showWaitingMessage && (
          <div className="info-text">
            <span>{t<string>("chargingProgress.label.waitingMessage")}</span>
          </div>
        )}

        {showErrorMessage && !showWaitingMessage && (
          <>
            <div className="charging-error mb-3">
              <div className="session-complete-div">
                <img
                  src={require("../../assets/images/ic_error_icon.png")}
                  className="error-img"
                  alt="success"
                />
                <span className="session-error-span ">
                  {t<string>("chargingProgress.label.error")}
                </span>
              </div>
              <div className="session-complete-message">
                <span>
                  {t<string>("chargingProgress.label.unableToConnect")}
                </span>
              </div>
            </div>
            <div className="clearfix mt-2 pb-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => backbtnclick()}
              >
                <span>{t<string>("paymentFail.button.back")}</span>
              </button>
            </div>
          </>
        )}

        {!showWaitingMessage && paymentDetails !== null && (
          <>
            {(showCompletedMessage || isSessionCompleted) && (
              <>
                <div className="charging-complete mb-4">
                  <div className="session-complete-div">
                    <img
                      src={require("../../assets/images/ic_stop_charge_success_icon.png")}
                      className="marker-img"
                      alt="success"
                    />
                    <span className="session-complete-span ">
                      {t<string>("chargingProgress.label.sessionComplete")}
                    </span>
                  </div>
                  <div className="session-complete-message">
                    <span>
                      {paymentFailMessage
                        ? t<string>(
                            "chargingProgress.label.thankYouForCharging"
                          )
                        : thankYouMessage}
                    </span>
                  </div>
                </div>
              </>
            )}
            {paymentDetails.pre_auth_payment_status === "SETTLED" &&
              paymentDetails.settlement_payment_status === "SETTLED" && (
                <>
                  {isPaymentRetry && (
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="mesg-box text-center">
                          <img
                            src={
                              require("../../assets/images/success.svg").default
                            }
                            alt="success"
                            className="success-img"
                          />
                          <h2 className="text-success">
                            {t<string>("paymentSuccess.label")}
                          </h2>
                          <p>{t<string>("paymentSuccess.Successlabel")}</p>
                          <div className="charging-complete mb-4">
                            <div className="session-complete-message">
                              <span>{thankYouMessage}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="clearfix mt-2 stop-charge-div">
                    <button
                      type="button"
                      className="btn btn-primary mr-2 stop-charge-btn"
                      onClick={() => handleDownloadInvoice()}
                    >
                      <span className="stop-charge-span">
                        {t<string>("chargingProgress.label.downloadInvoice")}
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary stop-charge-btn"
                      onClick={() => backbtnclick()}
                    >
                      <span className="stop-charge-span">
                        {t<string>("paymentFail.button.back")}
                      </span>
                    </button>
                  </div>
                </>
              )}

            {(paymentDetails.settlement_payment_status === "FAILED" ||
              paymentDetails.settlement_payment_status ===
                "SETTLEMENT_FAILED") && (
              <>
                {isPaymentRetry && (
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="mesg-box text-center">
                        <img
                          src={
                            require("../../assets/images/error-icon.svg")
                              .default
                          }
                          alt="error"
                          className="success-img"
                        />
                        <h2 className="error-color">
                          {t<string>("paymentFail.title")}
                        </h2>
                        <p>{t<string>("paymentFail.failureLabel")}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="instruction-title">
                  <h6 className="sub-title">
                    {t<string>("chargingProgress.label.paymentDetails")}
                  </h6>
                </div>
                <div className="charging-error mb-4">
                  <div className="session-complete-div">
                    <img
                      src={require("../../assets/images/ic_error_icon.png")}
                      className="error-img"
                      alt="error"
                    />
                    <span className="session-error-span ">
                      {t<string>("chargingProgress.label.paymentFailedTitle")}
                    </span>
                  </div>
                  <div className="session-complete-message">
                    <span>
                      {allPaymentsFailed
                        ? allPaymentsFailedMessage
                        : paymentFailMessage}
                    </span>
                  </div>
                </div>
                <div className="settlement-failed">
                  {showTryAgain && settlementAmount && settlementAmount < 0 && (
                    <div className="clearfix mt-2">
                      <button
                        type="button"
                        className="btn btn-primary mr-2 settlement-failed-btn"
                        onClick={() => handleTryAgain()}
                      >
                        <span className="stop-charge-span">
                          {t<string>("chargingProgress.label.tryAgain")}
                        </span>
                      </button>
                    </div>
                  )}
                  {showPayWithDifferentCard && (
                    <div className="clearfix mt-2 stop-charge-div">
                      <button
                        type="button"
                        className="btn btn-primary mr-2 settlement-failed-btn"
                        onClick={() => handlePayWithDifferentCard()}
                      >
                        <span className="stop-charge-span">
                          {t<string>(
                            "chargingProgress.label.payWithDifferentCard"
                          ).replace(
                            "##AMOUNT##",
                            amountToPay != null
                              ? "£" + amountToPay.toString()
                              : ""
                          )}
                        </span>
                      </button>
                    </div>
                  )}
                  {allPaymentsFailed && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => backbtnclick()}
                    >
                      <span>{t<string>("paymentFail.button.back")}</span>
                    </button>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ChargingProgress;
