import React, { FC, useEffect, useRef, useState } from "react";
import { ICommonProps } from "../../types/propTypes";
import ChargingService from "../../services/charging/charging-service";
import { IReceiptInvoiceDetails, IVatReceipt } from "../../models/charging";
import StandardResponse from "../../models/standard-response";
import moment from "moment";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import VATReceipt from "../../pages/charging/VatReceipt";
import NoDataReceipt from "./NoDataReceipt";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const chargingService = new ChargingService();
const DownloadVATReceipt: FC<ICommonProps> = (props) => {
  const { t } = props;
  const { session_id } = useParams();
  const [receiptDetails, setReceiptDetails] = useState<IVatReceipt | null>(
    null
  );
  const [isAPICalled, setIsAPICalled] = useState<boolean>(false);
  const didMount = useRef(false);

  useEffect(() => {
    if (session_id) {
      getReceiptData();
    }
  }, []);

  const getReceiptData = async () => {
    const inputData = {
      encrypted_session_id: session_id,
      is_download: false,
    };

    await chargingService
      .getDownloadReceiptData(inputData)
      .then(async (res: StandardResponse<IReceiptInvoiceDetails>) => {
        const vatReceiptData: any = res.Data;
        vatReceiptData.date = moment(new Date()).format("DD-MMM-YY");

        setReceiptDetails(vatReceiptData);
        setIsAPICalled(true);
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
  };

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }

    if ((document as any).getElementById("instaVoltReceiptDownload")) {
      setTimeout(() => {
        (document as any).getElementById("instaVoltReceiptDownload").click();
      }, 2000);
    }
  }, [receiptDetails]);

  return (
    <>
      {isAPICalled &&
        receiptDetails &&
        Object.keys(receiptDetails).length > 0 && (
          <>
            <div style={{ display: "none" }}>
              <PDFDownloadLink
                document={<VATReceipt data={receiptDetails} t={t} />}
                fileName={`InstaVolt_Rapid_EV_Charging_${moment(
                  new Date()
                ).format("DD_MM_YY")}.pdf`}
                className="btn icon-btn pr-0 btn-outline-primary"
              >
                {() => <div id="instaVoltReceiptDownload"></div>}
              </PDFDownloadLink>
            </div>
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              <VATReceipt data={receiptDetails} t={t} isDisplay={true} />
            </PDFViewer>
          </>
        )}
      {isAPICalled &&
        receiptDetails &&
        Object.keys(receiptDetails).length <= 0 && (
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <NoDataReceipt t={t} />
          </PDFViewer>
        )}
    </>
  );
};
export default DownloadVATReceipt;
