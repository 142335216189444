import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Helmet } from "react-helmet";
import SearchPageService from "../../services/search-page/search-page-service";
import { useParams, useNavigate } from "react-router-dom";
import StandardResponse from "../../models/standard-response";
import { toast } from "react-toastify";
import Switch from "react-switch";
import "./style.css";
import { ICommonProps } from "../../types/propTypes";
import CommonService from "../../services/common/common-service";
import { Common } from "../../utils/constants";
import CardValidationModal from "./CardValidationModal";
import { ITariffAvailable } from "../../models/search-page";
const searchPageService = new SearchPageService();
const commonService = new CommonService();

const StationDetails: FC<ICommonProps> = (props) => {
  const { t } = props;
  const { code } = useParams();
  const navigate = useNavigate();
  const [stationDetails, setStationDetails] = useState<any | null>(null);
  const [defaultLocationImageURL, setdefaultLocationImageURL] = useState<
    any | null
  >(null);
  const [showCardValidationModal, setShowCardValidationModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (code && code.length > 10) {
      toast.error(t<string>("stationDetails.error.invalidCode"));
      navigate("/");
    } else {
      const getStationDetailsByCode = async (code) => {
        await searchPageService
          .getStationDetailsByCode(code)
          .then((res: StandardResponse<any>) => {
            if (res.Data && Object.keys(res.Data).length !== 0) {
              setStationDetails(res.Data);
              getDefaultLocationImage();
              if (
                res.Data.charge_station_status &&
                res.Data.charge_station_status &&
                res.Data.charge_station_status.code === "CHARGING"
              ) {
                toggleCardValidationModal(true);
              }
            } else {
              toast.error(t<string>("stationDetails.error.stationNotFound"));
              navigate("/");
            }
          })
          .catch((m) => {
            if (m.response && m.response.data && m.response.data.message) {
              toast.error(m.response.data.message);
            } else {
              toast.error(m.toString());
            }
            navigate("/");
          });
      };
      const getDefaultLocationImage = async () => {
        await commonService
          .getConfigurationDetail({
            code: Common.ConfigurationKey.DEFAULT_LOCATION_IMAGE,
          })
          .then((res) => {
            setdefaultLocationImageURL(res.Data[0]?.value);
          })
          .catch((e) => {
            console.error("error", e);
          });
      };

      getStationDetailsByCode(code);
    }
  }, []);

  const backToHome = () => {
    navigate("/");
  };

  const toggleCardValidationModal = (showModal) => {
    setShowCardValidationModal(showModal);
  };

  const checkTariffAvailability = async (connector_id: number) => {
    await searchPageService
      .checkIsTariffApplied(connector_id)
      .then((res: StandardResponse<ITariffAvailable>) => {
        if (res.Data.available) {
          navigate(`start-charge/${connector_id}`, {
            state: stationDetails,
          });
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
  };
  return (
    <>
      <Helmet>
        <title>{t<string>("stationDetails.title")}</title>
      </Helmet>
      {stationDetails && Object.keys(stationDetails).length > 0 ? (
        <>
          <div className="container-fluid p-0 station-details">
            <div className="img-div">
              <img
                className="station-img"
                src={
                  stationDetails.charge_location_image ||
                  defaultLocationImageURL
                }
                alt="Station Details"
              />
              <button
                title={t("stationDetails.button.back")}
                type="button"
                className="btn btn-icon pr-0 btn-outline-primary back-button"
                onClick={() => backToHome()}
                data-testid="back-btn"
              >
                <img
                  className="icon back-button-icon"
                  src={require("../../assets/images/back-arrow.svg").default}
                  alt={t("stationDetails.button.back")}
                />
              </button>
            </div>
            <div className="row no-gutters justify-content-between">
              <div className="col-xs-12 col-md-6 sub-title-div">
                <h2 className="sub-title">
                  {stationDetails.charge_station_name}
                </h2>
              </div>
            </div>
            <div className="row no-gutters justify-content-between">
              <div className="col-xs-12 cost-div">
                <h6 className="details-title">
                  {t<string>("stationDetails.label.address")}
                </h6>
                &nbsp;
                <span>{`${stationDetails.charge_location_address}`}</span>
              </div>
            </div>
            <div className="row no-gutters justify-content-between">
              <div className="col-xs-12 cost-div">
                <h6 className="details-title">
                  {t<string>("stationDetails.label.cost")}
                </h6>
                &nbsp;
                <span>{`${stationDetails.price_per_kwh}`}</span>
              </div>
            </div>
            {stationDetails.charge_location_amenities && (
              <div className="row no-gutters justify-content-between details-div">
                <div className="col-12 padding-left-20">
                  <h6 className="details-title">
                    {t<string>("stationDetails.label.amenities")}
                  </h6>
                </div>
                <div className="col-12 padding-left-20">
                  {stationDetails.charge_location_amenities.map((amenity) => {
                    return (
                      <>
                        <img
                          src={amenity.image}
                          className="amenity-img"
                          alt={amenity.name}
                          key={amenity.id}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="row no-gutters justify-content-between charge-station-div">
              <div className="col-12 charge-station">
                <div className="station-title-div">
                  <div className="station-name text-truncate">
                    {stationDetails.charge_station_name}
                  </div>
                  <div className="station-status-div">
                    <div className="station-status-badge">
                      <span
                        className="badge badge-info"
                        style={{
                          background:
                            stationDetails.charge_station_status.color,
                        }}
                      >
                        {stationDetails.charge_station_status &&
                          stationDetails.charge_station_status.name}
                      </span>
                    </div>
                    <div className="float-right">
                      <img
                        src={
                          stationDetails.charge_station_status.name ===
                          "Unknown"
                            ? require("../../assets/images/grey-charger.png")
                            : stationDetails.charge_station_status.image
                        }
                        className="charger-img"
                        alt="charger"
                      ></img>
                    </div>
                  </div>
                </div>
                {stationDetails.connectors &&
                  stationDetails.connectors.map((connector) => {
                    return (
                      <>
                        <div className="station-title-div" key={connector.id}>
                          <div className="connector-title-div">
                            <img
                              src={connector.connector_image}
                              className="connector-img"
                              alt="charger"
                            />
                            <div className="connector-title">
                              <span className="connector-name">
                                {connector.title}
                              </span>
                              <span className="connector-volt">
                                {connector.voltage}
                              </span>
                            </div>
                          </div>
                          {stationDetails.charge_station_status.code ===
                            "AVAILABLE" && (
                            <div className="float-right">
                              <div>
                                <Switch
                                  onChange={(checked, event) => {
                                    if (
                                      event &&
                                      event.target &&
                                      (!(event.target as any).onclick ||
                                        event.type === "touchend") &&
                                      checked
                                    ) {
                                      checkTariffAvailability(connector.id);
                                    }
                                  }}
                                  width={118}
                                  checked={false}
                                  checkedIcon={false}
                                  uncheckedIcon={
                                    <span>
                                      {t<string>(
                                        "stationDetails.label.slideToCharge"
                                      )}
                                    </span>
                                  }
                                  offColor="#D3D3D3"
                                  onColor="#2cae1e"
                                  key={connector.id}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          {showCardValidationModal && (
            <CardValidationModal
              t={t}
              showModal={showCardValidationModal}
              onHide={() => {
                toggleCardValidationModal(false);
              }}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default StationDetails;
