import { IStation, ITariffAvailable } from "../../models/search-page";
import StandardResponse from "../../models/standard-response";
import BaseService from "../base-service";

export default class SearchPageService {
  getCodesList = async (data): Promise<StandardResponse<IStation[]>> => {
    try {
      const res = await BaseService({
        url: `web-pay/charge-locations/stations/search`,
        method: "POST",
        data: { ...data, hideSpinner: true },
      });
      return new StandardResponse<IStation[]>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
  getStationDetailsByCode = async (
    unique_code
  ): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `web-pay/charge-locations/stations/${unique_code}`,
        method: "GET",
        data: { hideSpinner: false },
      });

      return new StandardResponse<any>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
  validateCard = async (data): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `web-pay/charge-sessions/continue-session`,
        method: "POST",
        data,
      });

      return new StandardResponse<any>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
  checkIsTariffApplied = async (
    connector_id: number
  ): Promise<StandardResponse<ITariffAvailable>> => {
    try {
      const res = await BaseService({
        url: `web-pay/connector/${connector_id}/tariff-availability`,
        method: "GET",
      });

      return new StandardResponse<ITariffAvailable>(
        res.status,
        res.data.data,
        res.data.message,
        ""
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
}
